import { default as callbackAvVC23cRGOMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/airtable/callback.vue?macro=true";
import { default as _91slug_93E48feOSnBZMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/features/[slug].vue?macro=true";
import { default as indexeXCb6bSkroMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/features/index.vue?macro=true";
import { default as editN2oLcd6oIEMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexQbEdqzkamMMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsf5xRhyv7jAMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexEtmXbrmGdkMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexidKmQDV2YCMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareYj8yR29cQvMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showaqrBCsMTgXMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as index6fTJ1XimHBMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/create/index.vue?macro=true";
import { default as homengHmXfpB6jMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/home.vue?macro=true";
import { default as index0qRQip0X3YMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/index.vue?macro=true";
import { default as logintzXoQuKyZAMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/login.vue?macro=true";
import { default as callbackxZyDin85BMMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/oauth/callback.vue?macro=true";
import { default as onboardingKDbOEzycA7Meta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/onboarding.vue?macro=true";
import { default as _91token_93bsqH5VCZPzMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexLlSpKAbAM7Meta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingpca0CuqcQRMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policydxjPWlKHWTMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/privacy-policy.vue?macro=true";
import { default as registercsBWSiL30lMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/register.vue?macro=true";
import { default as access_45tokensnC3nST6avjMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountzOeBmFHh6NMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/account.vue?macro=true";
import { default as adminvxalsuKLA9Meta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/admin.vue?macro=true";
import { default as billingKb1ac7pDeSMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsTRjJNjS8lCMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domaindli8nqkXC5Meta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as indexNpYMOY423sMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/index.vue?macro=true";
import { default as passwordSoiXSpulkdMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/password.vue?macro=true";
import { default as profile6qM1XvKI8hMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacessM5PbhMWDiMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsZCRiwyeOrfMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/settings.vue?macro=true";
import { default as errorNj7JRdPakVMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successZQkWNllqtyMeta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsl6104LscD1Meta } from "/codebuild/output/src3717825931/src/airtableforms/client/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: "airtable-callback",
    path: "/airtable/callback",
    meta: callbackAvVC23cRGOMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/airtable/callback.vue").then(m => m.default || m)
  },
  {
    name: "features-slug",
    path: "/features/:slug()",
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editN2oLcd6oIEMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showaqrBCsMTgXMeta?.name,
    path: "/forms/:slug()/show",
    meta: showaqrBCsMTgXMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show-analytics",
    path: "analytics",
    meta: analyticsf5xRhyv7jAMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexEtmXbrmGdkMeta?.redirect,
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexidKmQDV2YCMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareYj8yR29cQvMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: index6fTJ1XimHBMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homengHmXfpB6jMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logintzXoQuKyZAMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingKDbOEzycA7Meta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93bsqH5VCZPzMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password/reset",
    meta: indexLlSpKAbAM7Meta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registercsBWSiL30lMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsZCRiwyeOrfMeta?.name,
    path: "/settings",
    meta: settingsZCRiwyeOrfMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountzOeBmFHh6NMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminvxalsuKLA9Meta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingKb1ac7pDeSMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsTRjJNjS8lCMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings-custom-domain",
    path: "custom-domain",
    meta: custom_45domaindli8nqkXC5Meta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexNpYMOY423sMeta?.redirect,
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordSoiXSpulkdMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profile6qM1XvKI8hMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspaces",
    path: "workspaces",
    meta: workspacessM5PbhMWDiMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorNj7JRdPakVMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successZQkWNllqtyMeta || {},
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    component: () => import("/codebuild/output/src3717825931/src/airtableforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  }
]