<template>
  <input-wrapper v-bind="inputWrapperProps">
    <template #label>
      <slot name="label" />
    </template>

    <v-select
      v-model="compVal"
      :data="filteredUsers"
      :label="label"
      :remote="remote"
      :searchable="true"
      option-key="id"
      emit-key="id"
      :required="required"
      :loading="loading"
      :multiple="multiple"
      :color="color"
      :placeholder="placeholder"
      :uppercase-labels="uppercaseLabels"
      :theme="theme"
      :has-error="hasError"
      :disabled="disabled"
      :help="help"
      :help-position="helpPosition"
    >
      <template #selected="{ option }">
        <div
          v-if="multiple"
          class="flex gap-1 flex-wrap items-center w-full"
        >
          <div
            v-for="opt in selectedOptions(option)"
            :key="opt.id"
            class="flex flex-grow max-w-full truncate text-center items-center"
          >
            <img
              v-if="opt.avatar_url && isUrl(opt.avatar_url)"
              loading="lazy"
              :src="opt.avatar_url"
              :alt="opt.avatar_url + ' icon'"
              class="flex-shrink-0 h-6 w-6 rounded-full"
            >
            <div
              v-else
              class="rounded-full bg-nt-blue-lighter h-6 w-6 text-sm text-center"
              v-text="opt.name.charAt(0)"
            />
            <p
              v-if="showNameWithAvatar"
              class="ml-2"
            >
              {{ opt.name }}
            </p>
          </div>
        </div>
        <div
          v-for="opt in selectedOptions(option)"
          v-else
          :key="opt.id"
          class="flex"
        >
          <img
            v-if="opt.avatar_url && isUrl(opt.avatar_url)"
            loading="lazy"
            :src="opt.avatar_url"
            :alt="opt.avatar_url + ' icon'"
            class="flex-shrink-0 h-6 w-6 rounded-full"
          >
          <div
            v-else
            class="rounded-full bg-nt-blue-lighter h-6 w-6 text-sm text-center"
            v-text="opt.name.charAt(0)"
          />
          <p
            v-if="showNameWithAvatar"
            class="ml-2"
          >
            {{ opt.name }}
          </p>
        </div>
      </template>
      <template #option="{ option, selected }">
        <span class="flex items-center space-x-3">
          <img
            v-if="isUrl(option.avatar_url)"
            loading="lazy"
            :src="option.avatar_url"
            :alt="option.name + ' avatar'"
            class="flex-shrink-0 h-6 w-6 rounded-full"
          >
          <div
            v-else
            class="rounded-full bg-nt-blue-lighter h-6 w-6 text-sm text-center"
            v-text="option.name.charAt(0)"
          />
          <span
            class="block truncate dark:text-white"
          >
            {{ option.name }}
          </span>
        </span>

        <span
          v-if="selected"
          class="absolute inset-y-0 right-0 flex items-center pr-4 dark:text-white"
        >
          <Icon
            name="heroicons:check-16-solid"
            class="w-5 h-5"
          />
        </span>
      </template>
    </v-select>

    <template #help>
      <slot name="help" />
    </template>

    <template #error>
      <slot name="error" />
    </template>
  </input-wrapper>
</template>

<script>
import Fuse from "fuse.js"
import { inputProps, useFormInput } from "../useFormInput.js"
import InputWrapper from "../components/InputWrapper.vue"

export default {
  name: "UserInput",
  components: { InputWrapper },
  props: {
    ...inputProps,
    optionKey: { type: String, default: "id" },
    label: { type: String, default: null },
    help: { type: String, default: null },
    workspaceId: { type: Number, required: false },
    formSlug: { type: String, required: false },
    multiple: { type: Boolean, required: false, default: true },
    showNameWithAvatar: { type: Boolean, required: false, default: true }
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const airtableUsersStore = useAirtableUsersStore()
    const workspacesStore = useWorkspacesStore()
    return {
      airtableUsersStore,
      workspacesStore,
      ...useFormInput(props, context),
    }
  },
  data() {
    return {
      userFilter: "",
    }
  },
  computed: {
    loading () {
      return this.notionUsersStore.loading
    },
    users () {
      return this.notionUsersStore.getAll
    },
    userFetchUrl() {
      if (this.formSlug) {
        return "/forms/" + this.formSlug + "/users"
      } else if (this.workspaceId) {
        return "/airtable/workspaces/" + this.workspaceId + "/users"
      }
      return null
    },
    filteredUsers() {
      if (this.userFilter === "") return this.users
      if (this.users.length === 0) return []

      // Fuze search
      const fuse = new Fuse(this.users, { keys: ["name"] })
      return fuse.search(this.userFilter).map((res) => {
        return res.item
      })
    },
  },
  mounted() {
    if (!this.multiple && Array.isArray(this.compVal)) {
      this.compVal = this.compVal[0] ?? null
    }
    if (!this.airtableUsersStore.allLoaded) {
      this.airtableUsersStore.loadAll(this.userFetchUrl)
    } else {
      this.airtableUsersStore.stopLoading()
    }
  },
  methods: {
    loadUsers() {
      this.loading = false
      airtableFormsFetch(this.userFetchUrl).then((data) => {
        this.users = data.sort((a, b) => {
          return a.name > b.name ? 1 : -1
        })
        this.loading = false
      })
    },
    select(value) {
      this.$emit("update:modelValue", value)
    },
    selectedOptions(ids) {
      return this.users.filter((user) => {
        return ids.includes(user.id)
      })
    },
    isUrl(str) {
      try {
        new URL(str)
      } catch (_) {
        return false
      }
      return true
    },
    remote(search) {
      this.userFilter = search
    },
  },
}
</script>
